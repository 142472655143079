import { Injectable, Injector } from "@angular/core";
import UserEditDto from "../../models/users/user.edit.dto";
import { UrlProvider } from "../../provider/url.provider";

@Injectable()
export class UsersService {
  private readonly url: string = 'users';

  constructor(private readonly urlProvider: UrlProvider) {

  }

  public get() {
    return this.urlProvider.get(this.url);
  }

  public getById(id: string) {
    return this.urlProvider.get(this.url + "/" + id);
  }

  public add(model: UserEditDto) {
    return this.urlProvider.post(this.url, model);
  }

  public edit(id: string, model: UserEditDto) {
    return this.urlProvider.put(this.url + "/" + id, model);
  }

  public delete(id: string) {
    return this.urlProvider.delete(this.url + "/" + id);
  }

}
