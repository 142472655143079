import { Injectable } from '@angular/core';
import { UserRole } from '../app/enums/user/user.role';
import { BaseGuard } from './base.guard';


@Injectable()
export class ClientGuard extends BaseGuard {
   
    canActivate() {
        if (this.isNotBrowser()){
            return true;
        }
        
        let isAuth = this.gVars.hasRole(UserRole.Client);
        if (isAuth == false) {
            this.routeHelper.navigateTo();
        }
        return isAuth;
    }
}