import { IntegrationSettingEditModule } from './views/settings/edit.integration.settings/integration.settings.edit.module';
import { AppModule } from './app.module';
import { RecoverPasswordComponent } from './views/login/recover-password/recoverpassword.component';
import { CoreModule } from './core.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthGuard } from '../guard/no.auth.guard';
import { ClientGuard } from '../guard/client.guard';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { services } from './services/services';
import { pipes } from './pipes/PipeModule';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
    canActivate: [NoAuthGuard]
  },
  {
    path: 'recover',
    component: RecoverPasswordComponent,
    data: {
      title: 'Recover Page'
    },
    canActivate: [NoAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    },
    canActivate: [NoAuthGuard]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [ClientGuard],
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'dashboard',
        title: "SPPO | Overview",

        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'automations',
        title: "SPPO | Automations",
        loadChildren: () => import('./views/automations/automations.module').then(m => m.AutomationsModule)
      },
      {
        path: 'demand-partners',
        loadChildren: () => import('./views/demand-partner/demand-partners.module').then(m => m.DemandPartnersModule)
      },
      {
        path: 'demand-partners/:demandId',
        loadChildren: () => import('./views/demand-partner/demand-partner.edit/demand-partner.edit.module').then(m => m.DemandPartnerEditModule)
      },
      {
        path: 'automation/:automationId',
        loadChildren: () => import('./views/automations/automations.edit/automation.edit.module').then(m => m.AutomationEditModule)
      },
      {
        path: 'integrations',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'integrations/:settingid',
        loadChildren: () => import('./views/settings/edit.integration.settings/integration.settings.edit.module').then(m => m.IntegrationSettingEditModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./views/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'hub',
        title: "SPPO | Hub",
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportssModule)
      },
      {
        path: 'alarms',
        title: "SPPO | Alerts",
        loadChildren: () => import('./views/alarms/alarms.module').then(m => m.AlarmsModule)
      },
      {
        path: 'alarms/:Id',
        title: "SPPO | Alerts",
        loadChildren: () => import('./views/alarms/alarm.edit/alarm.edit.module').then(m => m.AlarmEditModule)
      },
      {
        path: 'reports/:Id',
        title: "SPPO | Report",
        loadChildren: () => import('./views/reports/report.edit/report.edit.module').then(m => m.ReportEditModule)
      },
      {
        path: 'reportview/:Id',
        title: "SPPO | Report",
        loadChildren: () => import('./views/reports/report.view/report.view.module').then(m => m.ReportViewModule)
      },
      {
        path: 'performance',
        title: "SPPO | Performnace",
        loadChildren: () => import('./views/performance/performance.module').then(m => m.PerformanceModule),
      },
      {
        path: 'automated-throttling',
        title: "SPPO | Automated Throttling",
        loadChildren: () => import('./views/automated-throttling/automated-throttling.module').then(m => m.AutomatedThrottlingModule),
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ 
    RouterModule.forRoot(routes, { 
      onSameUrlNavigation: 'reload' }),
  CoreModule ],
  exports: [
     RouterModule 
    ],
  providers: [
    services,
    pipes
  ]
})
export class AppRoutingModule {}
