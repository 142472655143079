import { Injectable } from '@angular/core';
import { BaseGuard } from './base.guard';

@Injectable()
export class NoAuthGuard extends BaseGuard {

    canActivate() {
        if (this.isNotBrowser()){
            return true;
        }

        let isAuth = this.gVars.checkAuth();
        if (isAuth == true) {
            this.routeHelper.navigateTo();
        }
        return !isAuth;
    }

}
