import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import IntegrationListTypeRequestDto from '../../models/automations/integration.list.type.request.dto';


@Injectable()
export class DSPIntegrationsService {

  private url: string = 'DSPIntegration/';

  constructor(private readonly urlProvider: UrlProvider) {
  }

  public getPlacements(model: IntegrationListTypeRequestDto) {
    return this.urlProvider.post(this.url + 'placements', model)
  }

}
