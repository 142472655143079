import { Observable } from 'rxjs';
import { UrlProvider } from '../provider/url.provider';


export default class CustomObservable<T>{

    public observable: Observable<T>;
    private _showLoader: boolean = false;
    private _ignoreErrors: boolean = false;
    private _doRefresh: any = null;
    private _onErrorAction: any = null;
    private _externalAuthrization: boolean = false;
    public constructor(
        private readonly o: Observable<T>, 
        private readonly _context: UrlProvider){
        this.observable = o;
    }

    public showLoader(): CustomObservable<T>{
        this._showLoader = true;
        return this;
    }

    public ignoreErrors(): CustomObservable<T>{
        this._ignoreErrors = true;
        return this;
    }

    public externalRequest(): CustomObservable<T>{
        this._externalAuthrization = true;
        return this;
    }

    public doRefresh(event: any): CustomObservable<T>{
        if (event != null && event.target != null){
            this._doRefresh = event.target;
        }
        return this;
    }

    private doRefreshComplete(){
        if (this._doRefresh && this._doRefresh.complete){
            this._doRefresh.complete();
        }
    }

    public addOnErrorAction(action): CustomObservable<T>{
        this._onErrorAction = action;
        return this;
    }

    public execute(activateForm?: Function): Observable<any>{

        this._context._runLoader(this._showLoader);

        const n = new Observable<any>((observer) => {
            this.observable.subscribe(res => {
                this._context._stopLoader(this._showLoader);
                if (activateForm){
                    activateForm();
                }
                observer.next(<any> res);
                observer.complete();
                this.doRefreshComplete();
            }, error => {
                this._context._stopLoader(this._showLoader);
                this._context._displayError(this._ignoreErrors, error, this._onErrorAction);
                if (activateForm){
                    activateForm();
                }
                this.doRefreshComplete();
                //this._context.rollbar.error(typeof(CustomObservable).name, error);
                observer.error(error);
                observer.complete();
            })
        })
        return n;
    }

    public static empty(){
        return new Observable<any>(subscriber => {
            subscriber.next(null);
            subscriber.complete();
        });
    }
}