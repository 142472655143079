import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '../app/enums/user/user.role';
import { GlobalVars } from '../app/vars/global.vars';



@Injectable({ 'providedIn': 'root' })
export class RouteHelper {

  constructor(
    private readonly gVars: GlobalVars,
    private readonly router: Router) {

  }

  public navigateTo(isNewUser: boolean = false) {
    if (this.gVars.checkAuth() == false) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

}

