import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import LoginDto from '../../models/users/current/login.dto';
import { UserService } from '../../services/data/user.service';
import { GlobalVars } from '../../vars/global.vars';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SignalRService } from '../../services/system/signalr.service';

@Component({
             selector: 'app-dashboard',
             templateUrl: 'login.component.html',
             styleUrls: [ './login.component.scss' ]
           })

export class LoginComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  public toggle = false;
  public remember = false;
  public loginForm: FormGroup;
  public error = false;
  public login: LoginDto = {
    email: null,
    password: null
  };

  constructor(
    public readonly gVars: GlobalVars,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService,
    private cookieService: CookieService,
    private signalRService: SignalRService) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      'email': new FormControl('', [ Validators.required, Validators.email]),
     'password': new FormControl('', [ Validators.required]),
    'remember': new FormControl(false ),
    });
  }

  toggleFieldType(): void {
    this.toggle = !this.toggle;
  }

  onSubmit() {
    if (!this.loginForm.value) {
      return;
    }
    const remember = this.loginForm.value.remember;
    this.login.email = this.loginForm.value.email;
    this.login.password = this.loginForm.value.password;
    this.sub = this.userService.login(this.login).showLoader().execute().subscribe(res => {
      this.gVars.setUser(res);
      this.gVars.loadAuthorized(true, false);
      this.signalRService.startConnection();
    }, err =>  this.error = true
    );
    if (remember) {
      this.cookieService.set('email',  `${this.login.email}`);
      this.cookieService.set('password',  `${this.login.password}`);
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
