import { NoAuthGuard } from './no.auth.guard';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { BrandGuard } from './brand.guard';
import { ClientGuard } from './client.guard';

export const guards = [
    NoAuthGuard,
    AuthGuard,
    AdminGuard,
    ClientGuard,
]