import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { GlobalVars } from "../vars/global.vars";
import { LoaderService } from "../services/system/loader.service";
import CustomObservable from "../overrides/custom.observable";

@Injectable()

export class UrlProvider {

    private readonly apiUrl: string;
    constructor(
        private readonly http: HttpClient,
        private readonly loaderService: LoaderService,
        @Inject('API_URL') apiUrl: string,
        private readonly gVars: GlobalVars,
    ) {
        this.apiUrl = apiUrl;// + 'api/'
    }

    private setHeaders(): any {
        var user = this.gVars.user;
        if (user) {
            return {
                'Authorization': "Bearer " + user.token,
                'Access-Control-Allow-Origin': '*'
            }
        } else {
            return {};
        }
    }

    private setHeadersDownload(): any {
        var user = this.gVars.user;
        if (user) {
            return {
                'Authorization': "Bearer " + user.token,
                'Access-Control-Allow-Origin': '*'
            }
        } else {
            return {};
        }
    }

    private setExternalHeaders(): any {
        var user = this.gVars.user;
        if (user) {
            return {
                'Content-Type': 'application/json'
            }
        } else {
            return {};
        }
    }

    public getFile(apiUrl: string, params: any): CustomObservable<Response> {
        const e = this.http.post(this.apiUrl + apiUrl, params, { headers: this.setHeaders(), responseType: 'arraybuffer' });
        const q = this.toCustomObservable(e);
        return q;
    }

    public get(apiUrl: string, params: any = null): CustomObservable<Response> {
        let options: any = { headers: this.setHeaders() };
        if (params) {
            options.params = params;
        }
        const e = this.http.get(this.apiUrl + apiUrl, options);
        const q = this.toCustomObservable(e);
        return q;
    }

    public externalPost(apiUrl: string, obj: any): CustomObservable<Response> {
        const e = this.http.post(apiUrl, obj, { headers: this.setExternalHeaders() });
        const q = this.toCustomObservable(e).externalRequest().ignoreErrors();
        return q;
    }

    public post(apiUrl: string, obj: any): CustomObservable<Response> {
        const e = this.http.post(this.apiUrl + apiUrl, obj, { headers: this.setHeaders() });
        const q = this.toCustomObservable(e);
        return q;
    }

    public postFile(apiUrl: string, obj: any): CustomObservable<Response> {
        const e = this.http.post(this.apiUrl + apiUrl, obj, { headers: this.setHeadersDownload() });
        const q = this.toCustomObservable(e);
        return q;
    }

    public download(apiUrl: string, obj: any, headers?: HttpHeaders): CustomObservable<Blob> {
        const e = this.http.post(this.apiUrl + apiUrl, obj, { headers: this.setHeaders(), responseType: 'blob' });
        const q = this.toCustomObservable(e);
        return q;

    }

    public put(apiUrl: string, obj: any): CustomObservable<Response> {
        const e = this.http.put(this.apiUrl + apiUrl, obj, { headers: this.setHeaders() });
        const q = this.toCustomObservable(e);
        return q;
    }

    public delete(apiUrl: string, obj?: any): CustomObservable<Response> {
        const e = this.http.delete(this.apiUrl + apiUrl, { headers: this.setHeaders() });
        const q = this.toCustomObservable(e);
        return q;
    }

    public postExternal(externalUrl: string, obj: any): CustomObservable<Response> {
        var p = JSON.stringify(obj)
        const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
        const e = this.http.post(externalUrl, p, { headers: headers });
        const q = this.toCustomObservable(e);
        return q;
    }

    public postExternalTextType(externalUrl: string, obj: any): CustomObservable<Response> {
        const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');

        const e = this.http.post(externalUrl, obj, { headers: headers, responseType: 'text' });
        const q = this.toCustomObservable(e);
        return q;
    }


    public getExternal(externalUrl: string, params: any = null): CustomObservable<Response> {
        const e = this.http.get(externalUrl);
        const q = this.toCustomObservable(e);
        return q;
    }

    private toCustomObservable(e: Observable<any>): CustomObservable<any> {
        return new CustomObservable<any>(e, this);
    }

    public _runLoader(showLoader: boolean) {
        if (showLoader == true) {
            this.loaderService.loader.next(true);
        }
    }

    public _stopLoader(showLoader: boolean) {
        if (showLoader == true) {
            this.loaderService.loader.next(false);
        }
    }

    public _displayError(ignoreErrors: boolean, e: any, onErrorAction: any = null) {
        if (ignoreErrors != true) {
            try {
                if (onErrorAction == null) {
                    if (e.error) {
                        let errText = 'Unexpected error.';
                        if (e.error.message) {
                            errText = e.error.message + "<br>";
                        }

                        if (e.error.errors.length > 0) {
                            e.errors.forEach((e) => {
                                errText += `${e}<br>`;
                            })
                        }
                    } else {
                        //this.notificationService.error('Error', `Unexpected error.`);
                    }
                } else {
                    onErrorAction(e);
                }

            } catch (e) {
                console.error(e);
            }
        }
        if (e.status == 401 || e.status == 403) {
            if (this.gVars.user) {
                let userRole = this.gVars.user.roles[0];
            }
            this.gVars.delUser();
        }


    }
}
