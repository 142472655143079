import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';

import { BaseGuard } from './base.guard';
import { RouteHelper } from '../handlers/route.helper';
import { GlobalVars } from '../app/vars/global.vars';


@Injectable()
export class AuthGuard extends BaseGuard{
    
    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        protected readonly routeHelper: RouteHelper,
        protected readonly gVars: GlobalVars,
        private readonly router: Router
    ) {
        super(platformId, routeHelper, gVars);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (this.isNotBrowser()){
            return true;
        }
        let isAuth = this.gVars.checkAuth();
        if (isAuth != true) {

        }

        return isAuth;
    }
}