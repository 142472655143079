import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import AutomationEditDto from '../../models/automations/automation.edit.dto';
import DownloadListDto from '../../models/automations/logs/download.list.dto';
import { HttpHeaders } from '@angular/common/http';
import AutomationRkDto from '../../models/automations/automation.rk.dto';
import AutomationListFileUpdateDto from '../../models/automations/files/automation.list.file.update.dto';
import ThrottleDemandDto from '../../models/automations/throttle.demand.dto';


@Injectable()
export class AutomationService {

  private url: string = 'automation/';

  constructor(private readonly urlProvider: UrlProvider) {
  }

  public add(dto: AutomationEditDto) {
    return this.urlProvider.post(this.url + 'add', dto)
  }

  public edit(dto: AutomationEditDto) {
    return this.urlProvider.post(this.url + 'edit', dto)
  }

  public runAutomation(rk: AutomationRkDto) {
    return this.urlProvider.post(this.url + 'run', rk)
  }

  public get() {
    return this.urlProvider.get(this.url + 'all')
  }

  public getLogsByAutomation(rk: string) {
    return this.urlProvider.get(this.url + 'logsHistory/' + rk)
  }

  public getLogDetails(rk: string) {
    return this.urlProvider.get(this.url + 'logDetails/' + encodeURIComponent(rk))
  }

  public getById(rk: string) {
    return this.urlProvider.get(this.url + 'id/' + rk)
  }

  public updateList(listDto: AutomationListFileUpdateDto) {
    return this.urlProvider.post(this.url + 'updateList', listDto)
  }
  public downloadList(listDto: DownloadListDto) {
    return this.urlProvider.getFile(this.url + 'downloadlist', listDto)
  }

  public getLimelightDimensions(rk: string) {
    return this.urlProvider.get(this.url + 'LimelightDimensions/' + encodeURIComponent(rk))
  }

  public getSmartyDimensions(rk: string) {
    return this.urlProvider.get(this.url + 'SmartyDimensions/' + encodeURIComponent(rk))
  }

  public runLimelightDimension(dto: ThrottleDemandDto) {
    return this.urlProvider.post(this.url + 'RunLimelightDimension', dto)
  }

  public runSmartyDimension(dto: ThrottleDemandDto) {
    return this.urlProvider.post(this.url + 'runSmartyDimension', dto)
  }
}
