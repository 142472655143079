import { MatFormFieldModule } from '@angular/material/form-field';
import { RecoverPasswordComponent } from './views/login/recover-password/recoverpassword.component';
import { CoreModule } from './core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { services } from './services/services';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';

import { CommonModule } from '@angular/common';  


import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgChartsModule } from 'ng2-charts';
import { AuthGuard } from '../guard/auth.guard';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';
import { guards } from '../guard/guards';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EnumToArrayPipe } from './pipes/enum.array.pipe';
import { pipes } from './pipes/PipeModule';
import { CookieService } from 'ngx-cookie-service';
import { AgGridModule } from 'ag-grid-angular';
import { NavSidebarComponent } from './views/sidebar/sidebar.componet';
import { IsKeySetPipe } from './pipes/isKeySet.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { FiltersComponent } from './views/filters/filters.component';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiErrorInterceptor } from './services/api.error.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({
            imports: [
              BrowserModule,
              CommonModule,
              MatTabsModule,
              FormsModule,
              BrowserAnimationsModule,
              AppRoutingModule,
              AppFooterModule,
              AppHeaderModule,
              AppSidebarModule,
              BsDropdownModule.forRoot(),
              TabsModule.forRoot(),
              NgChartsModule,
              MatSelectModule,
              CoreModule,
              NgbModule,
              ReactiveFormsModule,
              MatButtonToggleModule,
              MatRadioModule,
              AgGridModule,
              ToastrModule.forRoot(),
              ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !isDevMode(),
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
              }),
            ],
            declarations: [
              AppComponent,
              ...APP_CONTAINERS,
              P404Component,
              P500Component,
              LoginComponent,
              RecoverPasswordComponent,
              RegisterComponent,
              NavSidebarComponent,
              
            ],
            providers: [
              {
                provide: LocationStrategy,
                useClass: HashLocationStrategy,
              },
              [ CookieService ],
              services,
              guards,
              EnumToArrayPipe,
              IsKeySetPipe,
              DatePipe,
              pipes,
              {provide: 'API_URL', useValue: environment.servers.api},
              { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
            ],
            exports: [
              AppComponent,
              ...APP_CONTAINERS,
              P404Component,
              P500Component,
              LoginComponent,
              RecoverPasswordComponent,
              RegisterComponent,
              NavSidebarComponent,
              MatFormFieldModule,
              MatInputModule
            ],
            bootstrap: [ AppComponent ]
          })
export class AppModule { }
