import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationService {
  private showNav$ = new BehaviorSubject(false);
  constructor() {}

  public getNav(): Observable<boolean> {
    return this.showNav$.asObservable();
  }
  public setShowNav(state: boolean): void {
    this.showNav$.next(state);
  }
}

