import { EnumToArrayPipe } from "./enum.array.pipe";


export const pipes = [
    EnumToArrayPipe,
    MomentDatePipe,
    IsKeySetPipe,
    
]

import { NgModule } from '@angular/core';
import { IsKeySetPipe } from "./isKeySet.pipe";
import { MomentDatePipe } from "./momentDate.pipe";

@NgModule({
    imports:        [],
    declarations:   [pipes],
    exports:        [pipes],
})

export class PipeModule {

  static forRoot() {
     return {
         ngModule: PipeModule,
         providers: [],
     };
  }
} 