import { UsersService } from './data/users.service';

import { LoaderService } from './system/loader.service';
import { StorageService } from './system/storage.service';
import { UserService } from './data/user.service';
import { AnalyticsService } from './data/analytics.service';
import { AutomationService } from './data/automation.service';
import { IntegrationsSettingsService } from './data/integrations.settings.service';
import { DemandPartnersService } from './data/demand-partners.service';
import { LimelightExternalService } from './data/limelight.service';
import { AlarmsService } from './data/alarms.service';
import { ReportsService } from './data/reports.service';
import { DSPIntegrationsService } from './data/dsp.integrations.settings.service';
import { SignalRService } from './system/signalr.service';
import { CloudWatchService } from './data/cloud-watch.service';



export const services = [
  //System
  LoaderService,
  StorageService,
  
  //Data
  AnalyticsService,
  AutomationService,
  LimelightExternalService,
  DemandPartnersService,
  IntegrationsSettingsService,
  DSPIntegrationsService,
  UserService,
  UsersService,
  AlarmsService,
  ReportsService,
  SignalRService,
  CloudWatchService
]