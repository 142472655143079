
import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import { HttpClient } from '@angular/common/http';
import CloudWatchNetworkDto from '../../models/network-analytics/cloud-watch-logs';

@Injectable()
export class CloudWatchService {

    private url: string = 'CloudWatch/';

    constructor(private readonly urlProvider: UrlProvider,
        private http: HttpClient) {
    }

    public getLogs(dto: CloudWatchNetworkDto) {
        return this.urlProvider.post(this.url + 'GetLogs', dto)
    }



}
