import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class AnalyticsService {

  private url: string = 'Analytics/';

  constructor(private readonly urlProvider: UrlProvider) {
  }

  public getAnalyticsData(fields: string[], filters: string[], startDate: string, endDate: string) {
    let params = new HttpParams();
    
    for (let id of fields) {
      params = params.append('fields', id);
    }

    for (let id of filters) {
      params = params.append('filters', id);
    }

    var urlQuery = this.url + 'getAnalytics?' + params +'&startDate=' + startDate + '&endDate=' + endDate; 

    return this.urlProvider.get(urlQuery); 
  }

  public getDashboardDailyAnalytics(startDate: string, endDate: string) {
    return this.urlProvider.get(this.url + 'DashboardDailyAnalytics?' + 'startDate=' + startDate + '&endDate=' + endDate);
  }
  public getDashboardWeeklyAnalytics() {
    return this.urlProvider.get(this.url + 'DashboardWeeklyAnalytics');
  }

}
