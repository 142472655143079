import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVars } from '../../vars/global.vars';
import { navItems } from '../../_nav';
import { CookieService } from 'ngx-cookie-service';
import { NavigationService } from '../../services/ui/navigation.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { StorageService } from '../../services/system/storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})

export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public hideSidebar = false;
  public navItems = navItems;
  isOpenSideBar$: Observable<boolean>;

  public systemTime = moment.utc(Date.now()).format('YYYY-MM-DD hh:mm:ss')
  public interval;

  constructor(
    public readonly gVars: GlobalVars,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    public readonly navService: NavigationService,
    private readonly storageService: StorageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isOpenSideBar$ = this.navService.getNav();
    this.setupTime()
  }

  setupTime() {
    this.systemTime =  moment.utc(Date.now()).format('YYYY-MM-DD hh:mm:ss');
    setInterval(() => {
      this.systemTime =  moment.utc(Date.now()).format('YYYY-MM-DD hh:mm:ss');
    }, 1000);
  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  movedSidebar(): void {
    this.hideSidebar = !this.hideSidebar;
    this.navService.setShowNav(this.hideSidebar);
  }
  public logout() {
    const userRole = this.gVars.user.roles[0];
    this.gVars.delUser();
    this.cookieService.delete('email');
    this.cookieService.delete('password');
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
