import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { GlobalVars } from '../../../vars/global.vars';
import { UserService } from '../../../services/data/user.service';
import RecoveryUserDto from '../../../models/users/current/recovery.user.dto';
import UserNewPasswordDto from '../../../models/users/current/user.new.password.dto';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})

export class RecoverPasswordComponent implements OnInit {

  public loginForm: FormGroup;
  public passwordFrom: FormGroup;

  public token = undefined;
  public userId = undefined;
  public error;

  constructor(
    public readonly gVars: GlobalVars,
    private readonly userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
    });

    this.passwordFrom = new FormGroup({
      'password': new FormControl('', [Validators.required, Validators.required]),
    });

    this.route.queryParams
    .subscribe(params => {
      this.token = encodeURIComponent(params.token);
      this.userId = params.userId;


    });

  }

  onSubmit() {
    if (!this.loginForm.value) {
      return;
    }
    var recoveryDto: RecoveryUserDto = {
      email: this.loginForm.value.email
    }

    this.userService.sendRecoveryPassword(recoveryDto).showLoader().execute().subscribe(res => {
    });

  }

  onSubmitNewPass() {
    if (!this.passwordFrom.value) {
      return;
    }
    var recoveryDto: UserNewPasswordDto = {
      token: this.token,
      userId: this.userId,
      password: this.passwordFrom.value.password
    }

    this.userService.recoveryPassword(recoveryDto).showLoader().execute().subscribe(res => {
    });

  }

  
}
