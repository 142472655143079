import { Injectable } from "@angular/core";
import UserReportDto from "../../models/reports/userReport.dto";
import { UrlProvider } from "../../provider/url.provider";

@Injectable()
export class ReportsService {

    private readonly url: string = 'report';

    constructor(private readonly urlProvider: UrlProvider) {
  
    }

    public getAll() {
        return this.urlProvider.get(this.url +'/all');
      }
      public getById(id) {
        return this.urlProvider.get(this.url +'/get/' + id);
      }

      public add(modal:UserReportDto) {
        return this.urlProvider.post(this.url +'/add',modal);
      }

      public edit(modal:UserReportDto) {
        return this.urlProvider.put(this.url +'/edit',modal);
      }

      public getMergedSettings() {
        return this.urlProvider.get(this.url +'/GetMergedSettings/' );
      }

      public removeById(id) {
        return this.urlProvider.delete(this.url +'/remove/' + id);
      }
}