import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import AlarmDto from '../../models/alarms/alarm.dto';
import { HttpClient } from '@angular/common/http';
import AlarmLogPaginationDto from '../../models/alarms/alarm.log.pagination.dto';

@Injectable()
export class AlarmsService {

  private url: string = 'Alarm/';

  constructor(private readonly urlProvider: UrlProvider,
    private http: HttpClient) {
  }
  public get() {
    return this.urlProvider.get(this.url + 'all')
  }
  public getById(rk: string) {
    return this.urlProvider.get(this.url + 'get/' + rk)
  }
  public getAllLogs(dto:AlarmLogPaginationDto) {
    return this.urlProvider.post(this.url + 'logs',dto)
  }

  public getLogsByRK(rk: string) {
    return this.urlProvider.get(this.url + 'logdetails/' + rk)
  }

  public edit(dto: AlarmDto) {
    return this.urlProvider.put(this.url + 'edit', dto)
  }

  public add(dto: AlarmDto) {
    return this.urlProvider.post(this.url + 'add', dto)
  }
  public getLogFile(alarmId, dateTime) {
   return this.urlProvider.post(this.url +'GetLogFile', {alarmId:alarmId,dateTime:dateTime});
  }

  public run(alarmId) {
    return this.urlProvider.get(this.url + 'run/' + alarmId)
   }

}
