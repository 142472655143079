import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { UrlProvider } from './provider/url.provider';
import { RouteHelper } from '../handlers/route.helper';
import { services } from './services/services';
import { guards } from '../guard/guards';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { systemModules } from './modules/systemModules';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { MatInputModule } from '@angular/material/input';

const baseLavel = [
    UrlProvider,
    RouteHelper,
    
]

const angularLibraries = [
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    RouterModule,
    GuiGridModule
]

const externalLibraries = [
    NgbDatepickerModule,
]

const materialDesignModules = [
   
]

@NgModule({
    imports: [
        angularLibraries,
        externalLibraries,
    ],
    declarations: [
        systemModules
    ],
    providers: [
        baseLavel,
        services,
        guards
    ],
    exports: [
        angularLibraries,
        externalLibraries,
        systemModules     
    ]
})
export class CoreModule { }
