import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { RouteHelper } from '../handlers/route.helper';
import { GlobalVars } from '../app/vars/global.vars';


@Injectable()
export class BaseGuard implements CanActivate {
    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: string,
        protected readonly routeHelper: RouteHelper,
        protected readonly gVars: GlobalVars
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        //this is need for ssr. Don`t touch!!!
        return true;
    }

    protected isNotBrowser(): boolean{
        return isPlatformBrowser(this.platformId) === false;
    }
}