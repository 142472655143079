import { Injectable } from "@angular/core";

@Injectable()
export class StorageService {

    private allowed: boolean = false;
    constructor() {
        try {
            this.allowed = true;
        } catch (e) {
            this.allowed = false;
        }
    }

    public set(key: string, dto: object) {
        var json = dto ? JSON.stringify(dto) : '';
        this.setAsString(key, json);
    }

    public get(key: string): any {
        var item = this.getAsString(key);
        return item && item.length > 0 ? JSON.parse(item) : null;
    }

    public getAsString(key: string): any {
        if (window && this.allowed == true) {
            var item = window.localStorage.getItem(key);
            return item && item.length > 0 ? item : null;
        }else {
            return null;
        }
    }

    public setAsString(key: string, value: string) {
        if (window && this.allowed == true) {
            window.localStorage.setItem(key, value);
        }
    }

    public del(key: string) {
        if (window && this.allowed == true)
            window.localStorage.removeItem(key);
    }
}
