import { Component, Input, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { NavigationService } from '../../services/ui/navigation.service';
import { Observable } from 'rxjs';
import { GlobalVars } from '../../vars/global.vars';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
             selector: 'app-nav-sidebar',
             templateUrl: 'sidebar.component.html',
             styleUrls: ['./sidebar.component.scss']
           })

export class NavSidebarComponent implements OnInit {
  @Input() hideSidebar = false;
  isOpenSideBar$: Observable<boolean>;
  navItems = navItems;
  activeIndex = 0;
  toggle = false;
  
  constructor(
    public navService: NavigationService,
    public readonly gVars: GlobalVars,
    private readonly router: Router,
    private cookieService: CookieService,
  ) {}

  public ngOnInit() {
    this.isOpenSideBar$ = this.navService.getNav();
    localStorage.getItem('activeNav') ? this.activeIndex = JSON.parse(localStorage.getItem('activeNav')) : this.activeIndex = 0;
  }
  public onActive(index: number): void {
    this.activeIndex = index;
    localStorage.setItem('activeNav', JSON.stringify(this.activeIndex));
  }

 public onToggleSidebar(): void {
   this.toggle = !this.toggle;
   this.navService.setShowNav(this.toggle);
 }

 public onLogout(): void {
   const userRole = this.gVars.user.roles[0];
   this.gVars.delUser();
   this.cookieService.delete('email');
   this.cookieService.delete('password');
   localStorage.clear();
   this.router.navigate(['/login']);
 }
}
