import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserRole } from '../enums/user/user.role';
import AuthorizedTransfer from '../models/transfers/authorized.transfer';
import AuthUser from '../models/users/current/auth.user';
import CurrentUser from '../models/users/current/current.user';
import { StorageService } from '../services/system/storage.service';

const USER = 'user';

@Injectable({ providedIn: 'root' })
export class GlobalVars {

  private _view: BehaviorSubject<'desktop' | 'mobile'> = new BehaviorSubject(null);
  public view$ = this._view.asObservable();

  public detectView() {
    if (document.body.offsetWidth < 500) {
      this._view.next('mobile');
    } else {
      this._view.next('desktop');
    }
  }

  public mobileView: BehaviorSubject<string> = new BehaviorSubject('spots');

  public RTL: boolean = false;

  public isOnline: boolean = false;
  public changeNavbar: boolean = false;
  public scrollSubject = new Subject<string>();
  public comingSoonModal: BehaviorSubject<any> = new BehaviorSubject(null)
  public openRoute: string = null;


  public user?: AuthUser;

  public isAuthorized: boolean = false;
  public Authorized: Subject<AuthorizedTransfer> = new Subject<AuthorizedTransfer>();

  public isShowLoader: boolean;

  constructor(
    private readonly storageService: StorageService
  ) {

    //ToDo optimize load privacy page
    let user = this.getUser();
    this.user = user;
    this.isAuthorized = this.checkAuth();

    this.Authorized.subscribe((authorizedTransfer: AuthorizedTransfer) => {
      this.isAuthorized = authorizedTransfer.idAuthorized;
    })
  }

  public hasRole(role: UserRole) {
    if (this.isAuthorized && this.user && this.user.roles && this.user.roles.indexOf(role) != -1) {
      return true;
    }
    return false;
  }

  public loadAuthorized(redirect: boolean = false, isNewUser: boolean): boolean {
    let user = this.getUser();
    this.user = user;
    this.isAuthorized = this.checkAuth();
    this.Authorized.next({ idAuthorized: this.isAuthorized, redirect: redirect, isNewUser: isNewUser });
    return this.isAuthorized;
  }

  public checkAuth(): boolean {
    var user = this.user;
    if (!user) {
      return false;
    }
    return true;
  }

  public setUser(appUser: AuthUser) {
    if (appUser) {
      this.user = appUser;
      this.storageService.set(USER, this.user);
    } else {
      this.storageService.del(USER);
    }
  }

  public delUser() {
    const userRole = this.user.roles[0];
    this.user = null;
    this.storageService.del(USER);
    this.storageService.del('networkAnalyticsPayload')
    this.isAuthorized = false;
    this.Authorized.next({ idAuthorized: this.isAuthorized, redirect: true, isNewUser: false });
  }

  public getUser(): AuthUser {
    return <AuthUser>this.storageService.get(USER);
  }

 
  public getUserid(): string {
    return (<AuthUser>this.storageService.get(USER)).id;
  }

  public updateUser(current: CurrentUser) {
    var user = this.getUser();
    if (user) {
      user.id = current.id;
      user.email = current.email;
      user.phone = current.phone;
      user.fullName = current.fullName;
      user.roles = current.roles;
      this.setUser(user);
    } else {
      this.delUser();
    }
  }

}
