import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import DemandPartnerDto from '../../models/demand-partner/DemandPartner.dto';

@Injectable()
export class DemandPartnersService {

  private url: string = 'DemandPartner/';

  constructor(private readonly urlProvider: UrlProvider) {
  }

  public add(dto: DemandPartnerDto) {
    return this.urlProvider.post(this.url + 'add', dto)
  }

  public edit(dto: DemandPartnerDto) {
    return this.urlProvider.post(this.url + 'edit', dto)
  }

  public getAutomationsByDemandPartner(rk: string) {
    return this.urlProvider.get(this.url + 'automations/' + rk)
  }

  public get() {
    return this.urlProvider.get(this.url + 'all')
  }

  public getById(rk: string) {
    return this.urlProvider.get(this.url + 'id/' + rk)
  }
  
}
