import { Injectable } from '@angular/core';
import { UserRole } from '../app/enums/user/user.role';
import { BaseGuard } from './base.guard';

@Injectable()
export class AdminGuard extends BaseGuard {

    canActivate() {
        if (this.isNotBrowser()){
            return true;
        }

        let isAuth = this.gVars.hasRole(UserRole.Admin);
        if (isAuth == false) {
            this.routeHelper.navigateTo(false);
        }
        return isAuth;
    }
}
