import { Injectable } from "@angular/core";
import LoginDto from "../../models/users/current/login.dto";
import RecoveryUserDto from "../../models/users/current/recovery.user.dto";
import UserNewPasswordDto from "../../models/users/current/user.new.password.dto";
import UserEditDto from "../../models/users/user.edit.dto";
import UserRegisterDto from "../../models/users/user.register.dto";
import { UrlProvider } from "../../provider/url.provider";


@Injectable({
    providedIn: 'root'
  })
export class UserService {
    private url: string = 'user';

    constructor(private readonly urlProvider: UrlProvider) {
    }

    public edit(user: UserEditDto) {
        return this.urlProvider.put(this.url + "/modify", user);
    }

    public getCurrent() {
        return this.urlProvider.get(this.url + '/getUser');
    }

    public login(dto: LoginDto) {
        return this.urlProvider.post(this.url + '/login', dto);
    }

    public sendRecoveryPassword(recoveryUserDto: RecoveryUserDto) {
        return this.urlProvider.post(this.url + '/SendRecoveryPassword', recoveryUserDto);
    }

    public recoveryPassword(userNewPasswordDto: UserNewPasswordDto) {
        return this.urlProvider.post(this.url + '/RecoveryPassword', userNewPasswordDto);
    }

}
