import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { GlobalVars } from "../../vars/global.vars";

@Injectable()
export class LoaderService {
    private calls: number = 0;

    public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public readonly gVars: GlobalVars) {
        this.loader.subscribe((value: boolean) => {
            
            if (value == true) {
                this.calls++;
            } else {
                if (this.calls > 0) {
                    this.calls--;
                }
            }
            this.gVars.isShowLoader = this.calls > 0;

        });
    }

}
