import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) {
     }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {

         // console.error('Client side error:', error.error.message);
        } else {
          //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
          //this.toastr.error('An internal error occurred during your request!');
        }
        return throwError(error);
      })
    );
  }
}