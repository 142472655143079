import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { GlobalVars } from '../../vars/global.vars';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: signalR.HubConnection;

    constructor(private readonly gVars: GlobalVars,
        private readonly router: Router,
        private readonly cookieService: CookieService,
        private readonly storageService: StorageService,) {
        this.startConnection();
    }

    public startConnection() {
        var user = this.gVars.user;
        if (user !== null) {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${environment.host}notificationHub`, {
                    accessTokenFactory: () => user.token
                })
                .build();

            this.hubConnection.start().catch(err => console.error('Error while starting SignalR connection: ' + err));

            this.hubConnection.on('Logout', () => {
                this.logout();
            });
        }
    }

    private logout() {
        const userRole = this.gVars.user.roles[0];
        this.gVars.delUser();
        this.cookieService.delete('email');
        this.cookieService.delete('password');
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
