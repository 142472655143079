import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'isKeySet'
})
export class IsKeySetPipe implements PipeTransform {
    transform(value: any, key?: string) {
         return key in value;
     }
}