import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import AutomationDto from '../../models/automations/automation.dto';
import IntegrationSettingDto from '../../models/automations/integration.settings.dto';
import IntegrationListTypeRequestDto from '../../models/automations/integration.list.type.request.dto';


@Injectable()
export class IntegrationsSettingsService {

  private url: string = 'IntegrationsSettings/';

  constructor(private readonly urlProvider: UrlProvider) {
  }

  public validateIntegrationCredential(dto: IntegrationSettingDto) {
    const credentials = {
      name: dto.username,
      password : dto.password
    }

    return this.urlProvider.externalPost(dto.integrationUrl + "v1/login", credentials)
  }

  public add(dto: any) {
    return this.urlProvider.post(this.url + 'add', dto)
  }

  public edit(dto: any) {
    return this.urlProvider.post(this.url + 'edit', dto)
  }

  public getPartners(rk: string) {
    return this.urlProvider.get(this.url + 'partners/' + rk);
  }

  public getAllByUserId() {
    return this.urlProvider.get(this.url + 'getAll')
  }

  public getById(rk: string) {
    return this.urlProvider.get(this.url + 'id/' + rk)
  }

  public getAvailable() {
    return this.urlProvider.get(this.url + 'available')
  }

  public getDimensionList(model: IntegrationListTypeRequestDto) {
    return this.urlProvider.post(this.url + "getDimensionList", model)
  }
  
  public getList(model: IntegrationListTypeRequestDto) {
    return this.urlProvider.post(this.url + "getLists", model)
  }

  public getFilteringList(model: IntegrationListTypeRequestDto) {
    return this.urlProvider.post(this.url + "FilteringList", model)
  }

  public getSettings(model: IntegrationListTypeRequestDto){
    return this.urlProvider.post(this.url + "GetSettings", model)
  }

  public getMagniteSettings(){
    return this.urlProvider.get(this.url + "GetMagniteSettings")
  }
  public getTripleLiftSettings(){
    return this.urlProvider.get(this.url + "GetTripleLiftSettings")
  }
  public getSmartyFilteringList(model: IntegrationListTypeRequestDto) {
    return this.urlProvider.post(this.url + "SmartyFilteringList", model)
  }

  public getMagniteFilteringSettings(){
    return this.urlProvider.get(this.url + "GetMagniteFilteringSettings")
  }

  public getMagniteFilteringList(model: IntegrationListTypeRequestDto) {
    return this.urlProvider.post(this.url + "MagniteFilteringList", model)
  }
}
