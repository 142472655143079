import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { GlobalVars } from './vars/global.vars';
import { RouteHelper } from '../handlers/route.helper';
import AuthorizedTransfer from './models/transfers/authorized.transfer';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { UserService } from './services/data/user.service';
import { SignalRService } from './services/system/signalr.service';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  sub: Subscription;
  private readonly versionKey = 'appVersion';
  constructor(
    private router: Router,
    public readonly gVars: GlobalVars,
    private readonly routeHelper: RouteHelper,
    private cookieService: CookieService,
    private readonly userService: UserService,
    private signalRService: SignalRService,
    private swUpdate: SwUpdate
  ) {

    this.gVars.Authorized.subscribe((authorizedTransfer: AuthorizedTransfer) => {
      if (authorizedTransfer.redirect === true) {
        this.routeHelper.navigateTo(authorizedTransfer.isNewUser);
      }
    });
  }
  ngOnInit() {
    if (this.gVars.loadAuthorized(false, false) === true) {
    }

    const storedVersion = localStorage.getItem(this.versionKey);
    if (storedVersion !== environment.appVersion) {
      localStorage.clear();
      localStorage.setItem(this.versionKey, environment.appVersion);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
