export const environment = {
  production: false,
  //host: "http://localhost:5005/",
  host: "https://dev.platform.app-stock.com/",

  servers: {
    //api: "http://localhost:5005/"
  api: "https://api.dev.platform.app-stock.com/"

  },
  debugger: {
    map: false
  },
  staticDomain: '',
  appVersion: '1.0.0'
 
}
