import { Injectable } from '@angular/core';
import { UrlProvider } from '../../provider/url.provider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import NetworkAnalyticsDto from '../../models/network-analytics/NetworkAnalytics';
import NetworkTableDto from '../../models/network-analytics/network-tablee';
import CompareNetworkDto from '../../models/network-analytics/compare-analytics';


@Injectable()
export class LimelightExternalService {

  private url: string = 'analytics/';

  constructor(private readonly urlProvider: UrlProvider) {
  }

  public getData(networkAnalyticsPayload: NetworkAnalyticsDto) {
    return this.urlProvider.post(this.url + 'GetAnalytics', networkAnalyticsPayload);
  }

  public defaultReports(networkAnalyticsPayload: NetworkAnalyticsDto) {
    return this.urlProvider.post(this.url + 'DefaultReports', networkAnalyticsPayload);
  }

  public getBundlesAvarages(tableAnalytics: NetworkTableDto) {
    return this.urlProvider.post(this.url + "GetBundlesByDemand", tableAnalytics);
  }

  public compareReports(networkPayload: CompareNetworkDto) {
    return this.urlProvider.post(this.url + 'CompareReports', networkPayload);
  }
}
